export const admRoutes = [
    { path: '', page: 'dashboard' },
    { path: 'translations', page: 'translations' },
    { path: 'users', page: 'users' },
    { path: 'careers', page: 'careers' },
    { path: 'languages', page: 'languages' },
    { path: 'pages', page: 'pages' },
    { path: 'logs', page: 'logs' },
    { path: 'images', page: 'images' },
    { path: 'documents', page: 'documents' },
    { path: 'slideShow', page: 'slideShow' },
    { path: '*', page: 'notFound' },
];
