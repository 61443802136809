
import BaseResponse from '@responses/BaseResponse';

class CareerListResponse extends BaseResponse {

    constructor(careerList, responseCode) {

        super(responseCode);
        this.careerList = careerList
    }
    
}

export default CareerListResponse;