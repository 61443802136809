
import BaseResponse from '@responses/BaseResponse';

class SlideShowListResponse extends BaseResponse {

    constructor(slideShowList, responseCode) {

        super(responseCode);
        this.slideShowList = slideShowList;
    }
    
}

export default SlideShowListResponse;