
import BaseResponse from '@responses/BaseResponse';

class UrlListResponse extends BaseResponse {

    constructor(urlList, responseCode) {

        super(responseCode);
        this.urlList = urlList;
    }
    
}

export default UrlListResponse;