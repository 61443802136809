
import axios from "axios";
import SlideShowListResponse from '@responses/SlideShowListResponse';
import UrlListResponse from '@responses/UrlListResponse';
import LanguageListResponse from '@responses/LanguageListResponse';
import TranslationListResponse from '@responses/TranslationListResponse';
import CareerDetailResponse from '@responses/CareerDetailResponse';
import CareerListResponse from '@responses/CareerListResponse';

class PageModel {

    static GetUrlList = async () => {
        try {
            const response = await axios.get('api/public/translation/url/list');
            const data = response.data;
            return new UrlListResponse(data.urlList, data.responseCode);
        } catch (error) {
            return new UrlListResponse([], error.response?.status || 500);
        }
    }

    static GetLanguageList = async () => {
        try {
            const response = await axios.get('api/public/language/list');
            const data = response.data;
            return new LanguageListResponse(data.languageList, data.responseCode);
        } catch (error) {
            return new LanguageListResponse([], error.response?.status || 500);
        }
    }

    static GetSlideShow = async () => {
        try {
            const response = await axios.get('api/public/slideshow/list');
            const data = response.data;
            return new SlideShowListResponse(data.slideList, data.responseCode);
        } catch (error) {
            return new SlideShowListResponse([], error.response?.status || 500);
        }
    }

    static GetTranslationList = async (language, page) => {
        try {
            const response = await axios.get(`api/public/translation/list/${language}/${page}`);
            const data = response.data;
            return new TranslationListResponse(data.translationList, data.responseCode);
        } catch (error) {
            return new TranslationListResponse([], error.response?.status || 500);
        }
    }

    static GetCareerList = async (language) => {
        try {
            const response = await axios.get(`/api/public/career/list/${language}`);
            const data = response.data;
            return new CareerListResponse(data.careerList, data.responseCode);
        } catch (error) {
            return new CareerListResponse([], error.response?.status || 500);
        }
    }

    static GetCareerDetail = async (language, name) => {
        try {
            const response = await axios.get(`/api/public/career/${language}/${name}`);
            const data = response.data;
            return new CareerDetailResponse(data.career, data.responseCode);
        } catch (error) {
            return new CareerDetailResponse(null, error.response?.status || 500);
        }
    }
}

export default PageModel;