import { useState, useEffect } from 'react';
import PageHelper from '@helpers/public/PageHelper';

const useUrlList = () => {
    const [urlList, setUrlList] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const admUrl = '/_adm';
        if (
            window.location.pathname.startsWith(`${admUrl}/`) ||
            window.location.pathname === admUrl
        ) {
            setLoading(false);
            return;
        }

        const helper = new PageHelper();
        helper.GetUrlList().then((response) => {
            setUrlList(response.urlList || []);
            setLoading(false);
        });
    }, []);

    return { urlList, loading };
};

export default useUrlList;
