
import BaseResponse from '@responses/BaseResponse';
class TranslationListResponse extends BaseResponse {
    constructor(translationList, allRowsCount, responseCode) {
        super(responseCode);
        this.translationList = translationList;
        this.allRowsCount = allRowsCount;
    }
}

export default TranslationListResponse;