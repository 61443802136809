
import BaseResponse from '@responses/BaseResponse';

class LanguageListResponse extends BaseResponse {

    constructor(languageList, responseCode) {

        super(responseCode);
        this.languageList = languageList;
    }
    
}

export default LanguageListResponse;